import React, { Component } from 'react';

import './course.css';

class Course extends Component {
  render() {
    let classes = 'col-md-2 hero';
    if (this.props.isChosen) {
      classes += ' chosen-one';
    }
    return (
      <div
        className={classes}
        onClick={e => this.props.clickFunction(e, this.props.id)}
      >
        <img alt={'Course'} src={this.props.image} />
        <br />
        <p className={'course'}>{this.props.label}</p>
      </div>
    );
  }
}

export default Course;
