import React, { Component } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../header/header.component';
import Container from '../form/studentForm.component';

class Home extends Component {
  render() {
    return (
      <div>
        <Header
          title={'QUERES FAZER PARTE DA SUPERTEAM?'}
          subtitle={
            'Então, precisamos de algumas informações para a tua candidatura!'
          }
        />
        <Container />
      </div>
    );
  }
}
export default Home;
