import React, { Component } from 'react';

import './header.scss';
import logo from './logo_Superteam.png';

class Header extends Component {
  render = () => {
    return (
      <div className={'text-center header'}>
        <div>
          <img alt={'Header'} src={logo} />
        </div>
        <div>
          <h3 className={'title'}>{this.props.title}</h3>
          <p>{this.props.subtitle}</p>
        </div>
      </div>
    );
  };
}

export default Header;
