import React, { Component } from 'react';

import './option.scss';

class Option extends Component {
  render() {
    let classes = 'col-md-3 option';
    if (this.props.isChosen) {
      classes += ' chosen-one';
    }

    return (
      <div
        className={classes}
        onClick={e => this.props.clickFunction(e, this.props.id)}
      >
        {this.props.label}
      </div>
    );
  }
}

export default Option;
