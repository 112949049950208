import { googleProvider } from '../../config/fbConfig';

export const googleLogin = () => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch({ type: 'WAITING_FOR_AUTH' });
    const firebase = getFirebase();
    firebase
      .auth()
      .signInWithPopup(googleProvider)
      .then(() => {
        dispatch({ type: 'LOGIN_SUCCESS' });
      })
      .catch(err => {
        dispatch({ type: 'LOGIN_ERROR', err });
      });
  };
};

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: 'SIGNOUT_SUCCESS' });
      })
      .catch(err => {
        dispatch({ type: 'SIGNOUT_ERROR', err });
      });
  };
};
