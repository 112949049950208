import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { googleLogin, signOut } from '../../store/actions/authActions';

function withAuthentication(WrappedComponent, title) {
  class Authenticate extends Component {
    render() {
      if (this.props.authError) {
        return (
          <div className="container-fluid definicoes">
            <h2>{title}</h2>
            <h4>Erro no início de sessão</h4>
            <p>
              Não foi possível iniciar sessão. Por favor confirme as suas
              credenciais.
            </p>
            <p>
              <Link to={'/'}>
                <Button className={'btn btn-secondary'}>
                  Voltar à página inicial
                </Button>
              </Link>
            </p>
            <Button
              className={'btn btn-primary'}
              onClick={() => {
                window.location.reload();
              }}
            >
              Tentar novamente
            </Button>
          </div>
        );
      }

      if (!this.props.auth.uid && !this.props.isWaitingForAuth) {
        return (
          <div className="container-fluid definicoes">
            <h2>{title}</h2>
            <Button className={'mt-3'} onClick={this.props.googleLogin}>
              Iniciar sessão
            </Button>
          </div>
        );
      }

      if (this.props.isWaitingForAuth) {
        return (
          <div className="container-fluid definicoes">
            <h2>{title}</h2>
            <h4>A entrar</h4>
            <p>A iniciar sessão utilizando a sua conta Google...</p>
          </div>
        );
      }

      if (
        this.props.auth.uid &&
        this.props.auth.email.indexOf('@opensoft.pt') === -1
      ) {
        return (
          <div className="container-fluid definicoes">
            <h2>{title}</h2>
            <h4>Acesso não autorizado</h4>
            <p>Não tem permissões para aceder a esta página</p>
            <p>
              <Link to={'/'}>
                <Button className={'btn btn-secondary'}>
                  Voltar à página inicial
                </Button>
              </Link>
            </p>
            <Button
              className={'btn btn-primary'}
              onClick={() => {
                this.props.signOut();
                window.location.reload();
              }}
            >
              Tentar novamente
            </Button>
          </div>
        );
      }

      return <WrappedComponent {...this.props} />;
    }
  }

  const mapStateToProps = state => {
    return {
      auth: state.firebase.auth,
      authError: state.auth.authError ? state.auth.authError.message : null,
      isWaitingForAuth: state.auth.isWaitingForAuth,
    };
  };

  const mapDispatchToProps = dispatch => {
    return {
      googleLogin: () => dispatch(googleLogin()),
      signOut: () => dispatch(signOut()),
    };
  };

  return withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(Authenticate),
  );
}

export default withAuthentication;
