import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
class ManageEventsPanel extends Component {
  handleMarcarComoAtual = (event, id) => {
    this.props.marcarComoEventoAtual(id);
  };

  render() {
    return (
      <div className="manage-panel manage-events-panel">
        <table className="table">
          <thead className="thead-light">
            <tr>
              <th>Nome</th>
              <th>Local</th>
              <th>Data início</th>
              <th>Data fim</th>
              <th />
              {/* Mark as current */}
              <th />
              {/* Edit */}
              <th />
              {/* Remove */}
            </tr>
          </thead>
          <tbody>
            {this.props.events &&
              this.props.events.map(event => {
                let startDate = new Date();
                startDate.setTime(event.startDate.seconds * 1000);
                let endDate = new Date();
                endDate.setTime(event.endDate.seconds * 1000);
                return (
                  <tr key={event.id}>
                    <td>{event.name}</td>
                    <td>{event.place}</td>
                    <td>{startDate.toLocaleDateString('PT-pt')}</td>
                    <td>{endDate.toLocaleDateString('PT-pt')}</td>
                    <td>
                      <button
                        className={
                          'btn btn-sm btn-primary' +
                          (event.active ? ' disabled' : '')
                        }
                        onClick={e => this.handleMarcarComoAtual(e, event.id)}
                      >
                        Marcar como ativo
                      </button>
                    </td>
                    <td>
                      <button className="btn btn-info btn-sm">Editar</button>
                    </td>
                    <td>
                      <button className="btn btn-danger btn-sm">Remover</button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    events: state.firestore.ordered.events
      ? state.firestore.ordered.events
      : [],
  };
};

const mapDispatchToProps = dispatch => {
  //TODO
};

export default withRouter(
  compose(
    firestoreConnect(['events']),
    connect(
      mapStateToProps,
      mapDispatchToProps,
    ),
  )(ManageEventsPanel),
);
