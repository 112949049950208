export const createStudent = student => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection('events')
      .where('active', '==', true)
      .get()
      .then(function(querySnapshot) {
        let eventId = querySnapshot.docs[0].id;
        const newStudent = {
          collegeId: '/collegeId/' + student.college,
          courseId: '/courses/' + student.course,
          courseYear: getYearValue(getState().year.years, student.year),
          email: student.email,
          eventId: '/events/' + eventId,
          name: student.name,
          registrationDate: new Date(),
          dataProtection: student.data_protection,
        };
        firestore
          .collection('students')
          .add(newStudent)
          .then(() => {
            dispatch({ type: 'CREATE_STUDENT', student });
          })
          .catch(err => {
            console.log(err);
            dispatch({ type: 'CREATE_STUDENT_ERROR', err });
          });
      });
  };
};

export const editStudent = student => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection('students')
      .doc(student.id)
      .set({
        collegeId: '/collegeId/' + student.college,
        courseId: '/courses/' + student.course,
        courseYear: Number(student.year),
        email: student.email,
        eventId: '/events/' + student.eventId,
        name: student.name,
        registrationDate: student.registrationDate,
        dataProtection: student.dataProtection,
      });
  };
};

export const removeStudent = id => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection('students')
      .doc(id)
      .delete();
  };
};

function getYearValue(years, yearName) {
  return years.filter(year => {
    return year.name === yearName;
  })[0].value;
}
