import React, { Component } from 'react';
import Course from './course.component';
import hero1 from './super_heroi01.png';
import hero2 from './super_heroi02.png';
import hero3 from './super_heroi03.png';
import hero4 from './super_heroi04.png';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

const hero_images = [hero1, hero2, hero3, hero4];
class CourseList extends Component {
  constructor() {
    super();
    this.state = {
      currentCourse: null,
    };
  }

  courseClicked = (e, id) => {
    this.setState(state => {
      return { currentCourse: id };
    });
    this.props.onClick(id);
  };

  render() {
    let i = -1;
    return (
      <div
        className={
          'courses row justify-content-center text-center option-group'
        }
      >
        {this.props.courses &&
          this.props.courses.map(course => {
            i++;
            return (
              <Course
                key={course.id}
                id={course.id}
                label={course.name}
                image={hero_images[i]}
                clickFunction={this.courseClicked}
                isChosen={this.state.currentCourse === course.id}
              />
            );
          })}
      </div>
    );
  }
}

export default compose(
  firestoreConnect(['courses']),
  connect((state, props) => ({
    courses: state.firestore.ordered.courses,
  })),
)(CourseList);
