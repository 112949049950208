export const marcarComoEventoAtual = id => async (
  dispatch,
  getState,
  { getFirebase, getFirestore },
) => {
  const firestore = getFirestore();
  let events = await firestore.collection('events').get();
  events.docs.forEach(doc => {
    if (doc.id === id) {
      firestore
        .collection('events')
        .doc(doc.id)
        .update({ active: true });
    } else {
      firestore
        .collection('events')
        .doc(doc.id)
        .update({ active: false });
    }
  });
};

export const downloadData = filters => async (
  dispatch,
  getState,
  { getFirebase, getFirestore },
) => {
  let students = await extractDataFromDB(getFirestore, filters);
  let content =
    '"Nome","Email","Universidade","Curso","Ano","Evento do registo","Data registo","RGPD"‎\n';
  students.forEach(student => {
    content +=
      student.name +
      ',' +
      student.email +
      ',' +
      student.college +
      ',' +
      student.course +
      ',' +
      student.year +
      ',' +
      student.event +
      ',' +
      student.date +
      ',' +
      student.data_protection +
      '\n';
  });
  download(content);
};

async function extractDataFromDB(getFirestore, filters) {
  const firestore = getFirestore();
  const students = [];
  let courses = {};
  let colleges = {};
  let events = {};
  let collegesDBResult = await firestore.collection('colleges').get();
  collegesDBResult.docs.forEach(doc => {
    colleges['/collegeId/' + doc.id] = doc.data();
  });
  let coursesDBResult = await firestore.collection('courses').get();
  coursesDBResult.docs.forEach(doc => {
    courses['/courses/' + doc.id] = doc.data();
  });
  let eventsDBResult = await firestore.collection('events').get();
  eventsDBResult.docs.forEach(doc => {
    events[doc.id] = doc.data();
  });
  let studentsDBResult = await firestore.collection('students').get();
  studentsDBResult.docs.forEach(doc => {
    let student = doc.data();
    let date = new Date();
    date.setTime(student.registrationDate.seconds * 1000);
    let realDate = new Date(date);
    date.setHours(1, 0, 0, 0);
    let event = student.eventId.substring(student.eventId.lastIndexOf('/') + 1);
    if (
      (filters.byDateStart ? filters.byDateStart <= date : true) &&
      (filters.byDateEnd ? filters.byDateEnd >= date : true) &&
      (filters.byEvent
        ? filters.byEvent === event || filters.byEvent === 'all'
        : true)
    ) {
      students.push({
        name: '"' + student.name + '"',
        email: student.email,
        college: '"' + colleges[student.collegeId].name + '"',
        course: '"' + courses[student.courseId].name + '"',
        year: student.courseYear,
        event: '"' + events[event].name + '"',
        date: date.toLocaleDateString('PT-pt'),
        realDate: realDate,
        data_protection: student.dataProtection ? 'Sim' : 'Não',
      });
    }
  });
  return students.sort((a, b) => {
    return b.realDate - a.realDate;
  });
}

function download(content) {
  let element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(content),
  );
  element.setAttribute('download', 'dados.csv');
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
