import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import { signOut } from '../../store/actions/authActions';
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import ManageSubmissionsPanel from './manageSubmissionsPanel.component';
import ManageEventsPanel from './manageEventsPanel.component';
import ManageCollegesPanel from './manageCollegesPanel.component';

class Manage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'submissoes',
    };
  }

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    return (
      <div className="container-fluid definicoes">
        <Button
          className={'btn btn-secondary btn-sm float-right'}
          onClick={this.props.signOut}
        >
          Terminar sessão
        </Button>
        <h2>Painel de Controlo da Opentour</h2>

        <Nav tabs>
          <NavItem>
            <NavLink
              className={this.state.activeTab === 'submissoes' ? 'active' : ''}
              onClick={() => {
                this.toggle('submissoes');
              }}
            >
              Submissões
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={this.state.activeTab === 'eventos' ? 'active' : ''}
              onClick={() => {
                this.toggle('eventos');
              }}
            >
              Eventos
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={this.state.activeTab === 'faculdades' ? 'active' : ''}
              onClick={() => {
                this.toggle('faculdades');
              }}
            >
              Faculdades
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="submissoes">
            <ManageSubmissionsPanel />
          </TabPane>
          <TabPane tabId="eventos">
            <ManageEventsPanel />
          </TabPane>
          <TabPane tabId="faculdades">
            <ManageCollegesPanel />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps,
  )(Manage),
);
