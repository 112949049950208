import React, { Component } from 'react';
import './App.scss';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Home from './components/home/home.screen';
import ThankYou from './components/thankyou/thankyou.screen';
import Manage from './components/manage/manage.screen';
import withAuthentication from './components/auth/withAuthentication';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appTitle: '',
    };
  }

  render() {
    return (
      <div className={'App'}>
        <BrowserRouter>
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/thankyou" component={ThankYou} exact />
            <Route
              path="/manage"
              component={withAuthentication(
                Manage,
                'Painel de Controlo da Opentour',
              )}
              exact
            />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
