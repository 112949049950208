import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
} from 'reactstrap';
import { editStudent } from '../../store/actions/studentActions';

class EditStudentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownCollegesOpen: false,
      dropdownCoursesOpen: false,
      dropdownYearsOpen: false,
      dropdownEventsOpen: false,
    };
  }

  toggleDropDownColleges = () => {
    let oldState = this.state.dropdownCollegesOpen;
    this.setState(() => {
      return {
        dropdownCollegesOpen: !oldState,
      };
    });
  };

  toggleDropDownCourses = () => {
    let oldState = this.state.dropdownCoursesOpen;
    this.setState(() => {
      return {
        dropdownCoursesOpen: !oldState,
      };
    });
  };

  toggleDropDownYears = () => {
    let oldState = this.state.dropdownYearsOpen;
    this.setState(() => {
      return {
        dropdownYearsOpen: !oldState,
      };
    });
  };

  toggleDropDownEvents = () => {
    let oldState = this.state.dropdownEventsOpen;
    this.setState(() => {
      return {
        dropdownEventsOpen: !oldState,
      };
    });
  };

  handleChangeName = event => {
    let newName = event.target.value;
    this.setState(() => {
      return {
        name: newName,
      };
    });
  };

  handleChangeEmail = event => {
    let newEmail = event.target.value;
    this.setState(() => {
      return {
        email: newEmail,
      };
    });
  };

  handleChangeUniversity = event => {
    let newUniversityName = event.target.dataset.name;
    let newUniversityId = event.target.dataset.id;
    this.setState(() => {
      return {
        universityName: newUniversityName,
        universityId: newUniversityId,
      };
    });
  };

  handleChangeCourse = event => {
    let newCourseName = event.target.dataset.name;
    let newCourseId = event.target.dataset.id;
    this.setState(() => {
      return {
        courseName: newCourseName,
        courseId: newCourseId,
      };
    });
  };

  handleChangeEvent = event => {
    let newEventName = event.target.dataset.name;
    let newEventId = event.target.dataset.id;
    this.setState(() => {
      return {
        eventName: newEventName,
        eventId: newEventId,
      };
    });
  };

  handleChangeYear = event => {
    let newYear = event.target.dataset.value;
    this.setState(() => {
      return {
        year: newYear,
      };
    });
  };

  handleChangeRegistrationDate = event => {
    let date = new Date();
    date.setTime(Date.parse(event.target.value));
    if (date instanceof Date && !isNaN(date)) {
      this.setState(() => {
        return {
          registrationDate: date,
        };
      });
    }
  };

  getCourseName() {
    if (this.state.courseName != null) {
      return this.state.courseName;
    } else if (this.props.courses && this.props.student) {
      for (let course of this.props.courses) {
        if (this.props.student.courseId.indexOf(course.id) >= 0) {
          return course.name;
        }
      }
    }
    return null;
  }

  getEventName() {
    if (this.state.eventName != null) {
      return this.state.eventName;
    } else if (this.props.events && this.props.student) {
      for (let event of this.props.events) {
        if (this.props.student.eventId.indexOf(event.id) >= 0) {
          return event.name;
        }
      }
      return null;
    }
  }

  getCollegeName() {
    if (this.state.universityName != null) {
      return this.state.universityName;
    } else if (this.props.colleges && this.props.student) {
      for (let college of this.props.colleges) {
        if (this.props.student.collegeId.indexOf(college.id) >= 0) {
          return college.name;
        }
      }
    }
    return null;
  }

  render() {
    let collegeName = this.getCollegeName();
    let courseName = this.getCourseName();
    let eventName = this.getEventName();
    let courseYear = null;
    if (this.state.year) {
      courseYear = this.state.year;
    } else if (this.props.student) {
      courseYear = this.props.student.courseYear;
    }
    let registrationDate;
    if (this.state.registrationDate) {
      registrationDate = this.state.registrationDate;
    } else {
      registrationDate = new Date();
      registrationDate.setTime(
        this.props.student
          ? this.props.student.registrationDate.seconds * 1000
          : 0,
      );
      registrationDate.setHours(1, 0, 0, 0);
    }
    let dateStr = registrationDate
      ? registrationDate.toISOString().substr(0, 10)
      : '';

    return (
      <Modal isOpen={this.props.open} toggle={this.props.closeModalFunction}>
        <ModalHeader toggle={this.props.closeModalFunction}>
          Editar submissão
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="name">Nome:</Label>
            <Input
              type="text"
              name="name"
              id="name"
              placeholder={'Nome'}
              onChange={this.handleChangeName}
              value={
                this.state.name != null
                  ? this.state.name
                  : this.props.student
                  ? this.props.student.name
                  : ''
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email:</Label>
            <Input
              type="email"
              name="email"
              id="email"
              placeholder={'Email'}
              onChange={this.handleChangeEmail}
              value={
                this.state.email != null
                  ? this.state.email
                  : this.props.student
                  ? this.props.student.email
                  : ''
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="college">Faculdade:</Label>
            <Dropdown
              id="college"
              isOpen={this.state.dropdownCollegesOpen}
              toggle={this.toggleDropDownColleges}
            >
              <DropdownToggle caret>
                {collegeName ? collegeName : 'Faculdade'}
              </DropdownToggle>
              <DropdownMenu>
                {this.props.colleges &&
                  this.props.colleges.map(college => {
                    return (
                      <DropdownItem
                        key={college.id}
                        data-id={college.id}
                        data-name={college.name}
                        active={
                          collegeName ? collegeName === college.name : false
                        }
                        onClick={this.handleChangeUniversity}
                      >
                        {college.name}
                      </DropdownItem>
                    );
                  })}
              </DropdownMenu>
            </Dropdown>
          </FormGroup>
          <FormGroup>
            <Label for="course">Curso:</Label>
            <Dropdown
              id="course"
              isOpen={this.state.dropdownCoursesOpen}
              toggle={this.toggleDropDownCourses}
            >
              <DropdownToggle caret>
                {courseName ? courseName : 'Curso'}
              </DropdownToggle>
              <DropdownMenu>
                {this.props.courses &&
                  this.props.courses.map(course => {
                    return (
                      <DropdownItem
                        key={course.id}
                        data-id={course.id}
                        data-name={course.name}
                        active={courseName ? courseName === course.name : false}
                        onClick={this.handleChangeCourse}
                      >
                        {course.name}
                      </DropdownItem>
                    );
                  })}
              </DropdownMenu>
            </Dropdown>
          </FormGroup>
          <FormGroup>
            <Label for="year">Ano:</Label>
            <Dropdown
              id="year"
              isOpen={this.state.dropdownYearsOpen}
              toggle={this.toggleDropDownYears}
            >
              <DropdownToggle caret>
                {courseYear == null
                  ? 'Ano'
                  : courseYear == 0
                  ? 'Outro'
                  : courseYear}
              </DropdownToggle>
              <DropdownMenu>
                {this.props.years &&
                  this.props.years.map(year => {
                    return (
                      <DropdownItem
                        key={year.value}
                        data-value={year.value}
                        active={
                          courseYear != null ? courseYear === year.value : false
                        }
                        onClick={this.handleChangeYear}
                      >
                        {year.value == 0 ? 'Outro' : year.value}
                      </DropdownItem>
                    );
                  })}
              </DropdownMenu>
            </Dropdown>
          </FormGroup>

          <FormGroup>
            <Label for="event">Evento:</Label>
            <Dropdown
              id="event"
              isOpen={this.state.dropdownEventsOpen}
              toggle={this.toggleDropDownEvents}
            >
              <DropdownToggle caret>
                {eventName ? eventName : 'Evento'}
              </DropdownToggle>
              <DropdownMenu>
                {this.props.events &&
                  this.props.events.map(event => {
                    return (
                      <DropdownItem
                        key={event.id}
                        data-name={event.name}
                        data-id={event.id}
                        active={eventName ? eventName === event.name : false}
                        onClick={this.handleChangeEvent}
                      >
                        {event.name}
                      </DropdownItem>
                    );
                  })}
              </DropdownMenu>
            </Dropdown>
          </FormGroup>
          <FormGroup>
            <Label for="date">Data de registo:</Label>
            <Input
              type="date"
              name="date"
              id="date"
              value={dateStr}
              onChange={this.handleChangeRegistrationDate}
              placeholder="Data de registo"
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              this.props.editStudent({
                id: this.props.student.id,
                name: this.state.name
                  ? this.state.name
                  : this.props.student.name,
                email: this.state.email
                  ? this.state.email
                  : this.props.student.email,
                college: this.state.universityId
                  ? this.state.universityId
                  : this.props.student.collegeId.substring(
                      this.props.student.collegeId.lastIndexOf('/') + 1,
                    ),
                course: this.state.courseId
                  ? this.state.courseId
                  : this.props.student.courseId.substring(
                      this.props.student.courseId.lastIndexOf('/') + 1,
                    ),
                year: this.state.year
                  ? this.state.year
                  : this.props.student.courseYear,
                eventId: this.state.eventId
                  ? this.state.eventId
                  : this.props.student.eventId.substring(
                      this.props.student.eventId.lastIndexOf('/') + 1,
                    ),
                registrationDate: registrationDate,
                dataProtection: this.props.student.dataProtection,
              });
              this.props.closeModalFunction();
            }}
          >
            Guardar alterações
          </Button>{' '}
          <Button color="secondary" onClick={this.props.closeModalFunction}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    student: state.firestore.ordered.students
      ? state.firestore.ordered.students.filter(student => {
          return student.id === props.studentId;
        })[0]
      : null,
    events: state.firestore.ordered.events,
    colleges: state.firestore.ordered.colleges,
    courses: state.firestore.ordered.courses,
    years: state.year.years,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    editStudent: student => dispatch(editStudent(student)),
  };
};

export default compose(
  firestoreConnect(['students', 'events', 'courses', 'colleges']),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(EditStudentModal);
