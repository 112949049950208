import React, { Component } from 'react';
import Option from './option.component';

class OptionList extends Component {
  constructor() {
    super();
    this.state = {
      currentYear: null,
    };
  }

  optionClicked = (e, id) => {
    this.setState(state => {
      return { currentYear: id };
    });
    this.props.onClick(id);
  };

  render() {
    let i = -1;
    return (
      <div className={'row justify-content-center option-group'}>
        {this.props.options &&
          this.props.options.map(option => {
            i++;
            return (
              <Option
                key={i}
                label={option}
                id={option}
                clickFunction={this.optionClicked}
                isChosen={this.state.currentYear === option}
              />
            );
          })}
      </div>
    );
  }
}

export default OptionList;
