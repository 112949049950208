import React, { Component } from 'react';
import EditStudentModal from './editStudentModal.component';
import RemoveStudentModal from './removeStudentModal.component';
import { downloadData } from '../../store/actions/otherActions';
import { removeStudent } from '../../store/actions/studentActions';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';

class ManageSubmissionsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editStudentModalShow: false,
      removeStudentModalShow: false,
      filters: {
        byDateStart: null,
        byDateEnd: null,
        byEvent: 'all',
      },
      currentStudent: null,
    };
  }

  handleChangeEventFilter = event => {
    let evt = event.target.value;
    this.setState(state => {
      return {
        filters: {
          byEvent: evt,
        },
      };
    });
  };

  handleChangeDateStartFilter = event => {
    let dateStart = event.target.value;
    let dateEnd = this.state.filters.byDateEnd;
    let byEvent = this.state.filters.byEvent;

    let date = new Date();
    date.setTime(Date.parse(dateStart));
    if (date instanceof Date && !isNaN(date)) {
      this.setState(state => {
        return {
          filters: {
            byDateStart: date,
            byDateEnd: dateEnd,
            byEvent: byEvent,
          },
        };
      });
    }
  };

  handleChangeDateEndFilter = event => {
    let dateEnd = event.target.value;
    let dateStart = this.state.filters.byDateStart;
    let byEvent = this.state.filters.byEvent;
    let date = new Date();
    date.setTime(Date.parse(dateEnd));
    if (date instanceof Date && !isNaN(date)) {
      this.setState(state => {
        return {
          filters: {
            byEvent: byEvent,
            byDateStart: dateStart,
            byDateEnd: date,
          },
        };
      });
    }
  };

  extrairDados = () => {
    this.props.downloadData(this.state.filters);
  };

  getCollegeById(collegeId) {
    return this.props.colleges.filter(clg => {
      let id = collegeId.substring(collegeId.lastIndexOf('/') + 1);
      return clg.id === id;
    })[0];
  }

  getCourseById(courseId) {
    return this.props.courses.filter(crs => {
      let id = courseId.substring(courseId.lastIndexOf('/') + 1);
      return crs.id === id;
    })[0];
  }

  getEventById(eventId) {
    return this.props.events.filter(evt => {
      let id = eventId.substring(eventId.lastIndexOf('/') + 1);
      return evt.id === id;
    })[0];
  }

  toggleEditStudentModal = event => {
    let oldState = this.state.editStudentModalShow;
    let studentId = event.target.dataset.student;
    this.setState(() => {
      return {
        editStudentModalShow: !oldState,
        currentStudent: studentId,
      };
    });
  };

  toggleRemoveStudentModal = event => {
    let oldState = this.state.removeStudentModalShow;
    let studentId = event.target.dataset.student;
    this.setState(() => {
      return {
        removeStudentModalShow: !oldState,
        currentStudent: studentId,
      };
    });
  };

  closeEditStudentModal = () => {
    this.setState(() => {
      return {
        editStudentModalShow: false,
      };
    });
  };

  closeRemoveStudentModal = () => {
    this.setState(() => {
      return {
        removeStudentModalShow: false,
      };
    });
  };

  removeFilters = () => {
    this.setState(state => {
      return {
        filters: {
          byDateStart: null,
          byDateEnd: null,
          byEvent: 'all',
        },
      };
    });
  };

  render() {
    let students = this.props.students.slice().sort((a, b) => {
      return b.registrationDate.seconds - a.registrationDate.seconds;
    });
    return (
      <div className="manage-panel manage-submissions-panel">
        <p>
          Filtrar por evento:&nbsp;
          <select
            onChange={this.handleChangeEventFilter}
            value={this.state.filters.byEvent}
          >
            <option value={'all'}>Todos os eventos</option>
            {this.props.events &&
              this.props.events.map(event => {
                return (
                  <option key={event.id} value={event.id}>
                    {event.name}
                  </option>
                );
              })}
          </select>
        </p>
        <p>
          Filtrar por data:
          <input
            type="date"
            placeholder={'Data de início'}
            onChange={this.handleChangeDateStartFilter}
            value={
              this.state.filters.byDateStart
                ? this.state.filters.byDateStart.toISOString().substr(0, 10)
                : ''
            }
          />
          a
          <input
            type="date"
            placeholder={'Data de fim'}
            onChange={this.handleChangeDateEndFilter}
            value={
              this.state.filters.byDateEnd
                ? this.state.filters.byDateEnd.toISOString().substr(0, 10)
                : ''
            }
          />
        </p>
        <button onClick={this.removeFilters}>Remover todos os filtros</button>
        <button className="extrair-dados-button" onClick={this.extrairDados}>
          Extrair dados filtrados
        </button>

        <table className="table">
          <thead className="thead-light">
            <tr>
              <th>Nome</th>
              <th>Email</th>
              <th>Faculdade</th>
              <th>Curso</th>
              <th>Ano</th>
              <th>Evento</th>
              <th>Data registo</th>
              <th />
              {/* Edit */}
              <th />
              {/* Remove */}
            </tr>
          </thead>
          <tbody>
            {students.map(student => {
              let registrationDate = new Date();
              registrationDate.setTime(student.registrationDate.seconds * 1000);
              registrationDate.setHours(1, 0, 0, 0);
              let college = this.getCollegeById(student.collegeId);
              let collegeName = college ? college.name : '';
              let course = this.getCourseById(student.courseId);
              let courseName = course ? course.name : '';
              let event = this.getEventById(student.eventId);
              let eventName = event ? event.name : '';
              if (
                college &&
                course &&
                event &&
                (this.state.filters.byDateStart
                  ? this.state.filters.byDateStart <= registrationDate
                  : true) &&
                (this.state.filters.byDateEnd
                  ? this.state.filters.byDateEnd >= registrationDate
                  : true) &&
                (this.state.filters.byEvent
                  ? this.state.filters.byEvent === event.id ||
                    this.state.filters.byEvent === 'all'
                  : true)
              ) {
                return (
                  <tr key={student.id}>
                    <td>{student.name}</td>
                    <td>{student.email}</td>
                    <td>{collegeName}</td>
                    <td>{courseName}</td>
                    <td>
                      {student.courseYear === 0 ? 'Outro' : student.courseYear}
                    </td>
                    <td>{eventName}</td>
                    <td>{registrationDate.toLocaleDateString('PT-pt')}</td>
                    <td>
                      <button
                        className="btn btn-sm btn-info"
                        data-student={student.id}
                        onClick={this.toggleEditStudentModal}
                      >
                        Editar
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn-danger btn-sm"
                        data-student={student.id}
                        onClick={this.toggleRemoveStudentModal}
                      >
                        Remover
                      </button>
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
        <EditStudentModal
          open={this.state.editStudentModalShow}
          studentId={this.state.currentStudent}
          closeModalFunction={this.closeEditStudentModal}
        />
        <RemoveStudentModal
          open={this.state.removeStudentModalShow}
          studentId={this.state.currentStudent}
          closeModalFunction={this.closeRemoveStudentModal}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    courses: state.firestore.ordered.courses
      ? state.firestore.ordered.courses
      : [],
    colleges: state.firestore.ordered.colleges
      ? state.firestore.ordered.colleges
      : [],
    years: state.year.years,
    students: state.firestore.ordered.students
      ? state.firestore.ordered.students
      : [],
    events: state.firestore.ordered.events
      ? state.firestore.ordered.events
      : [],
  };
};

const mapDispatchToProps = dispatch => {
  return {
    downloadData: filters => dispatch(downloadData(filters)),
    removeStudent: id => dispatch(removeStudent(id)),
  };
};

export default withRouter(
  compose(
    firestoreConnect(['colleges', 'students', 'events', 'courses']),
    connect(
      mapStateToProps,
      mapDispatchToProps,
    ),
  )(ManageSubmissionsPanel),
);
