const initState = {
  years: [
    { name: 'PRIMEIRO ANO', value: 1 },
    { name: 'SEGUNDO ANO', value: 2 },
    { name: 'TERCEIRO ANO', value: 3 },
    { name: 'QUARTO ANO', value: 4 },
    { name: 'QUINTO ANO', value: 5 },
    { name: 'OUTRO', value: 0 },
  ],
};

const yearReducer = (state = initState, action) => {
  return state;
};

export default yearReducer;
