import React, { Component } from 'react';
import Header from '../../components/header/header.component';
import { withRouter } from 'react-router-dom';

class ThankYou extends Component {
  render() {
    setTimeout(
      function() {
        this.props.history.push('/');
      }.bind(this),
      5000,
    );
    return (
      <Header
        title={'OBRIGADO PELA TUA CANDIDATURA!'}
        subtitle={'Terás novidades da Superteam em breve!'}
      />
    );
  }
}
export default withRouter(ThankYou);
