import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import './studentForm.scss';
import CollegeList from './collegeList';
import CourseList from './courses/courseList';
import OptionList from './option/optionsList';
import { createStudent } from '../../store/actions/studentActions';
import { withRouter } from 'react-router-dom';

class StudentForm extends Component {
  constructor() {
    super();
    this.state = {
      college: null,
      course: null,
      year: null,
      name: null,
      email: null,
      data_protection: null,
      errors: null,
    };
  }

  clickCollege = college => {
    this.setState(() => {
      return { college: college };
    });
  };

  clickCourse = course => {
    this.setState(() => {
      return { course: course };
    });
  };

  clickYear = year => {
    this.setState(() => {
      return { year: year };
    });
  };

  handleChangeName = e => {
    let newName = e.target.value;
    this.setState(() => {
      return { name: newName };
    });
  };

  handleChangeEmail = e => {
    let newEmail = e.target.value;
    this.setState(() => {
      return { email: newEmail };
    });
  };

  handleChangeDataProtection = e => {
    let newDataProtectionValue = e.target.checked;
    this.setState(() => {
      return { data_protection: newDataProtectionValue };
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    let errors = this.getErrors();
    if (!errors.hasErrors) {
      this.resetError();
      this.props.createStudent(this.state);
      this.props.history.push('/thankyou');
    } else {
      this.setState(() => {
        return {
          errors: errors.fields,
        };
      });
    }
  };

  getErrors() {
    let errors = {
      hasErrors: false,
      fields: {},
    };
    if (this.state.name == null) {
      errors['hasErrors'] = true;
      errors['fields']['name'] = 'Esqueceste-te de preencher o teu nome!';
    }
    if (this.state.college == null) {
      errors['hasErrors'] = true;
      errors['fields']['college'] = 'Esqueceste-te de indicar a tua faculdade!';
    }
    if (this.state.year == null) {
      errors['hasErrors'] = true;
      errors['fields']['year'] = 'Esqueceste-te de indicar em que ano estás!';
    }

    if (this.state.course == null) {
      errors['hasErrors'] = true;
      errors['fields']['course'] =
        'Esqueceste-te de indicar a que curso pertences!';
    }

    if (this.state.email == null) {
      errors['hasErrors'] = true;
      errors['fields']['email'] = 'Esqueceste-te de preencher o teu email!';
    }

    if (!this.state.data_protection) {
      errors['hasErrors'] = true;
      errors['fields']['data_protection'] =
        'Só podemos recolher os teus dados se concordares com a nossa política de privacidade';
    }
    return errors;
  }

  resetError() {
    this.setState(() => {
      return {
        errors: {},
      };
    });
  }

  render() {
    return (
      <div className={'container mainform'}>
        <Form onSubmit={this.handleSubmit}>
          <div className={'text-center'}>
            <p className={'titleContainer'}>QUAL A TUA FACULDADE?</p>
          </div>
          <CollegeList onClick={this.clickCollege} />
          {this.state.errors && this.state.errors['college'] && (
            <p className={'error'}>{this.state.errors['college']}</p>
          )}

          <div className={'text-center'}>
            <p className={'titleContainer '}>QUE CURSO FREQUENTAS?</p>
          </div>
          <CourseList onClick={this.clickCourse} />
          {this.state.errors && this.state.errors['course'] && (
            <p className={'error'}>{this.state.errors['course']}</p>
          )}

          <div className={'text-center'}>
            <p className={'titleContainer'}>QUAL O TEU ANO?</p>
          </div>
          <OptionList
            options={this.props.years.map(year => {
              return year.name;
            })}
            onClick={this.clickYear}
          />
          {this.state.errors && this.state.errors['year'] && (
            <p className={'error'}>{this.state.errors['year']}</p>
          )}
          <div
            className={'text-center'}
            style={{ paddingTop: '1%', paddingBottom: '2%' }}
          >
            <p className={'titleContainer'}>E PARA TERMINAR...</p>
          </div>

          <FormGroup>
            <Label className={'inputLabel'} for="name">
              Qual o teu nome?
            </Label>
            <Input
              type="text"
              name="name"
              id="name"
              placeholder={'Indica o teu nome'}
              onChange={this.handleChangeName}
            />
            {this.state.errors && this.state.errors['name'] && (
              <p className={'error'}>{this.state.errors['name']}</p>
            )}
          </FormGroup>
          <FormGroup>
            <Label className={'inputLabel'} for="email">
              Qual o teu email?
            </Label>
            <Input
              type="email"
              name="email"
              id="email"
              placeholder="Indica o teu email"
              onChange={this.handleChangeEmail}
            />
            {this.state.errors && this.state.errors['email'] && (
              <p className={'error'}>{this.state.errors['email']}</p>
            )}
          </FormGroup>
          <FormGroup check>
            <Input
              id="data_protection"
              type="checkbox"
              name="radio1"
              onChange={this.handleChangeDataProtection}
            />
            <Label check className={'checkbox'}>
              Aceito a{' '}
              <a
                href={'https://www.opensoft.pt/politica-de-protecao-de-dados'}
                target={'_blank'}
              >
                política de proteção de dados da Opensoft
              </a>
              .
            </Label>
          </FormGroup>
          {this.state.errors && this.state.errors['data_protection'] && (
            <p className={'error'}>{this.state.errors['data_protection']}</p>
          )}
          <div className={'row justify-content-center'}>
            <button
              type="submit"
              className="submit col-md-5 btn btn-primary option"
            >
              ENVIAR CANDIDATURA
            </button>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    courses: state.firestore.ordered.courses,
    colleges: state.firestore.ordered.colleges,
    years: state.year.years,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createStudent: student => dispatch(createStudent(student)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(StudentForm),
);
