import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { TabPane } from 'reactstrap';
class ManageCollegesPanel extends Component {
  render() {
    return (
      <div className="manage-panel manage-colleges-panel">
        <table className="table">
          <thead className="thead-light">
            <tr>
              <th>Nome</th>
              <th />
              {/* Edit */}
              <th />
              {/* Remove */}
            </tr>
          </thead>
          <tbody>
            {this.props.colleges &&
              this.props.colleges.map(college => {
                return (
                  <tr key={college.id}>
                    <td>{college.name}</td>
                    <td>
                      <button className="btn btn-info btn-sm">Editar</button>
                    </td>
                    <td>
                      <button className="btn btn-danger btn-sm">Remover</button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    colleges: state.firestore.ordered.colleges
      ? state.firestore.ordered.colleges
      : [],
  };
};

const mapDispatchToProps = dispatch => {
  //TODO
};

export default withRouter(
  compose(
    firestoreConnect(['colleges']),
    connect(
      mapStateToProps,
      mapDispatchToProps,
    ),
  )(ManageCollegesPanel),
);
