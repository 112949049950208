import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { removeStudent } from '../../store/actions/studentActions';
import { connect } from 'react-redux';

class RemoveStudentModal extends Component {
  render() {
    return (
      <Modal isOpen={this.props.open} toggle={this.props.closeModalFunction}>
        <ModalHeader toggle={this.props.closeModalFunction}>
          Remover submissão
        </ModalHeader>
        <ModalBody>Tem a certeza que deseja remover esta submissão?</ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => {
              this.props.removeStudent(this.props.studentId);
              this.props.closeModalFunction();
            }}
          >
            Remover
          </Button>{' '}
          <Button color="secondary" onClick={this.props.closeModalFunction}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    removeStudent: id => dispatch(removeStudent(id)),
  };
};

export default connect(
  null,
  mapDispatchToProps,
)(RemoveStudentModal);
