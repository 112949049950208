import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const CONFIG = {
  apiKey: 'AIzaSyDY-56L5PgHO7rSs7zaJxejW2fTzWgQP7s',
  authDomain: 'opentour-2019.firebaseapp.com',
  databaseURL: 'https://opentour-2019.firebaseio.com',
  projectId: 'opentour-2019',
  storageBucket: 'opentour-2019.appspot.com',
  messagingSenderId: '1006411831859',
};
firebase.initializeApp(CONFIG);

export default firebase;
export const googleProvider = new firebase.auth.GoogleAuthProvider();
