import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import Option from './option/option.component';

class CollegeList extends Component {
  constructor() {
    super();
    this.state = {
      currentCollege: null,
    };
  }

  collegeClicked = (e, id) => {
    this.setState(state => {
      return { currentCollege: id };
    });
    this.props.onClick(id);
  };

  render() {
    let i = -1;
    return (
      <div className={'row justify-content-center text-center option-group'}>
        {this.props.colleges &&
          this.props.colleges.map(college => {
            i++;
            return (
              <Option
                key={i}
                label={college.name}
                id={college.id}
                isChosen={this.state.currentCollege === college.id}
                clickFunction={this.collegeClicked}
              />
            );
          })}
      </div>
    );
  }
}

export default compose(
  firestoreConnect(['colleges']),
  connect((state, props) => ({
    colleges: state.firestore.ordered.colleges,
  })),
)(CollegeList);
